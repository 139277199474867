<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Our Team
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <p>With our team's diverse expertise in engineering, accounting, marketing, and law, we are equipped to tackle any challenge and deliver innovative solutions. Our fintech services and platform are designed with the highest level of security and reliability, ensuring that your financial transactions are safe and secure. Trust us to provide you with exceptional service and cutting-edge technology to meet all of your financial needs.</p>
                            <v-layout row wrap class="">
                                <v-flex xs12 sm6 md6 lg3 v-for="item in team_members" :key="item.id" class="pa-1">
                                    <v-hover>
                                        <v-card  slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" :to="item.link" style="cursor: pointer; border-radius: 5px">
                                            <v-img
                                                    :src="item.get_thumbnail"
                                                    aspect-ratio="2.75"
                                                    height="200px"
                                                    
                                            > </v-img>

                                            <v-card-title primary-title >
                                                <div>
                                                    <b class="heading mb-0" >{{item.position}}</b>
                                                    <h3 class="title ma-0 text--grey" ><b>{{item.name}}</b></h3>
                                                </div>
                                            </v-card-title>

                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                team_members: []
            }
        },

        mounted(){
            this.getOurTeamInfo();
            this.$store.commit('setCloseURL', "/")
        },
        
        methods:{
            async getOurTeamInfo(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/our_team/info/')
                    .then(response => {
                        this.team_members = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            }
        }

    }
</script>
